<template>
  <div class="holder">
    <h1></h1>
    <h2>
      展示得分环
      <span @click="playIn1('ringGaugeChart')">入场</span>
      <span @click="playOut('ringGaugeChart')">出场</span>
    </h2>
    <ring-gauge-chart
      ref="ringGaugeChart"
      id="ringGaugeChart"
      customClass="c_bar2 c_bar1"
    />
  </div>
</template>
<script>
import * as d3 from "d3";
import ringGaugeChart from "@/components/ringGaugeChart/index.vue";
export default {
  data: function () {
    return {
      data: [
        { value: 20, name: "Perfect" },
        { value: 40, name: "Good" },
        { value: 60, name: "Commonly" },
      ],
    };
  },
  components: { ringGaugeChart },
  methods: {
    playIn1(refName) {
      this.$refs[refName].ratioBarChart(this.data, {
        x: (d) => d.label,
        width: 300,
        height: 250,
        delay: 500,
        duration: 500,

        innerRadius: 60, //圆弧的内半径
        valueDomain: [0, 100], //
        arcWidth: 15,
        arcColors: ["red", "yellow", "green"],
        bgArcColor: "#e6ebf8",
        cornerRadius: 10,
        border: "black",
        space: 5,
      });
    },

    playOut(refName) {
      this.$refs[refName].playOut();
    },
  },
  mounted() {},
};
</script>
<style lang="less" scoped>
.holder {
  padding: 20px;
}
h2 {
  font-size: 18px;
  margin-bottom: 8px;
  font-weight: normal;
  color: #333;
  span {
    padding: 6px;
    border-radius: 6px;
    cursor: pointer;
    font-size: 14px;
    background: #999;
    color: #fff;
    user-select: none;
    margin: 0 3px;
  }
  span:nth-of-type(1) {
    background: #447746;
  }
  span:nth-of-type(2) {
    background: #953e23;
  }
}
h1 {
  font-size: 18px;
  color: #9c27b0;
  margin-bottom: 10px;
}
p {
  margin-bottom: 20px;
  color: #555;
}

.c_bar1 {
  background: #ffd918;
  background: -webkit-linear-gradient(bottom right, #ffd918, #e8882d);
  background: -moz-linear-gradient(bottom right, #ffd918, #e8882d);
  background: linear-gradient(to top left, #ffd918, #e8882d);
  font-size: 14px;
  min-height: 100px;
  display: inline-block;
  margin-bottom: 30px;
}
.box {
  margin: 10% auto;
  width: 600px;
  height: 400px;
  background-color: #ddd;
}

.title {
  font-size: 20px;
}

.label {
  font-size: 13px;
}

.polygon {
  fill-opacity: 0.8;
}
.c_bar2 {
  /deep/ .text {
    font-family: "harmonyMidium";
    font-size: 18px;
  }
}
</style>
