<template>
  <div :id="id" :class="customClass" class="rotate"></div>
</template>

<script>
import * as d3 from "d3";
import $ from "jquery";
export default {
  props: ["id", "customClass"],
  data() {
    return {
      svg: null,
      yScale: null,
      yDomain: [],
      ease: "easeBack",
      finalPoints: [],
      initialPoints: [],
    };
  },
  methods: {
    async playOut() {
      const that = this;
      this.svg.transition().duration(1000).style("opacity", "0");
    },
    async init() {
      await this.getSettings();
    },
    getSettings() {
      return new Promise((resolve, reject) => {
        this.$nextTick(() => {
          resolve();
        });
      });
    },
    //核心库
    ratioBarChart(
      data,
      {
        x = (d, i) => i, // given d in data, returns the (ordinal) x-value
        // y = (d) => d, // given d in data, returns the (quantitative) y-value
        // z = () => 1,
        width = 640, // the outer width of the chart, in pixels
        height = 400, // the outer height of the chart, in pixels
        // xDomain, // an array of (ordinal) x-values
        // xRange = [marginLeft, width - marginRight], // [left, right]
        // yDomain, // [ymin, ymax]
        // yRange = [height - marginBottom, marginTop], // [bottom, top]
        // zDomain, // array of z-values
        // zPadding = 0.05,
        // xPadding = 0.1, // 柱子的比例
        marginTop = 0, // the top margin, in pixels
        marginRight = 0, // the right margin, in pixels
        marginBottom = 0, // the bottom margin, in pixels
        marginLeft = 0, // the left margin, in pixels
        duration = 400, //动画持续时长
        delay = 100, //元素之间间隔时长

        innerRadius = 60, //圆弧的内半径
        arcMin = -Math.PI, //
        valueDomain = [0, 100], //

        arcWidth = 10, //圆环宽度
        arcColors = ["red", "yellow", "green"],
        bgArcColor = "transparent",
        cornerRadius = 0,
        border = "transparent",
        space = 0,
      } = {}
    ) {
      const that = this;
      this.duration = duration;
      this.delay = delay;

      const svg = d3
        .create("svg")
        .attr("width", width)
        .attr("height", height)
        // .attr("transform-box", "fill-box")
        // .attr("transform", "rotate(180deg)")
        .attr("viewBox", [0, 0, width, height])
        .attr("style", "max-width: 100%; height: auto; height: intrinsic;");
      this.svg = svg;

      //角度计算
      const angleScale = d3.scaleLinear(valueDomain, [-Math.PI, Math.PI]); //将value转化为角度
      const valueScale = d3.scaleLinear([-Math.PI, Math.PI], valueDomain); //将角度转化为value
      //   const suffix = !data.suffix ? "" : data.suffix; //数据单位

      const drawArc = function (deg, inR, outR) {
        return d3
          .arc()
          .innerRadius(inR)
          .outerRadius(outR)
          .cornerRadius(cornerRadius)
          .startAngle(arcMin)
          .endAngle(deg);
      };

      //根据r计算角度对应在圆的坐标
      function computedAxis(deg, r) {
        //deg是角度 r为半径
        const lineEndX = r * Math.sin(deg);
        const lineEndY = r * Math.cos(deg) * -1;
        return { x: lineEndX, y: lineEndY };
      }

      const bgArcPath = svg
        .append("g")
        .attr("transform", "translate(" + width / 2 + "," + height / 2 + ")")
        .attr("class", "bgArcPath")
        .append("path")
        .style("fill", bgArcColor)
        .transition()
        .duration(duration)
        .attrTween("d", function (d, i) {
          var angel = d3.interpolate(arcMin, Math.PI);
          return function (t) {
            let deg = angel(t);
            return drawArc(
              deg,
              innerRadius,
              innerRadius + arcWidth * data.length + space * (data.length - 1)
            )();
          };
        });

      const arcPath = svg
        .selectAll(".arcPath")
        .data(data)
        .enter()
        .append("g")
        .attr("transform", "translate(" + width / 2 + "," + height / 2 + ")")
        .attr("class", "arcPath");

      //画圆环
      arcPath
        .append("path")
        .style("fill", (d, i) => {
          return arcColors[i];
        })
        .attr("stroke", border)
        .attr("stroke-width", "1")
        .transition()
        .duration(duration)
        .attrTween("d", function (d, i) {
          var angel = d3.interpolate(arcMin, angleScale(d.value));
          return function (t) {
            let deg = angel(t);
            return drawArc(
              deg,
              innerRadius + arcWidth * i + space * i,
              innerRadius + arcWidth * (i + 1) + space * i
            )();
          };
        });

      $("#" + this.id).html(svg.node());
    },
  },
  mounted() {
    this.init();
  },
};
</script>
<style lang="less" scoped>
// @import "./index.less";
</style>
